<template>
  <div class="w-full max-w-6xl mx-auto">
    <div class="md:hidden w-full flex flex-wrap items-center justify-between">
      <div class="w-full flex justify-between px-4">
        <router-link to="/" class="my-auto">
          <img
            :src="require(isDark ? '@/assets/region-tacna-black.png' : '@/assets/region-tacna.png')"
            class="w-40"
            alt="Logo del Gobierno Regional de Tacna"
          />
        </router-link>

        <div class="flex gap-3 items-center">
          <img
            :src="
              require(isDark
                ? '@/assets/logo-contra-anemia-black.png'
                : '@/assets/logo-contra-anemia.png')
            "
            class="w-16"
            alt="Logo juntos contra la anemia"
          />
          <img
            :src="
              require(isDark ? '@/assets/logo-red-salud-black.png' : '@/assets/logo-red-salud.png')
            "
            class="w-16"
            alt="Logo de la red de salud Tacna"
          />
        </div>
      </div>

      <main class="text-center w-full">
        <div class="text-center">
          <h1 class="text-blue-500 font-semibold text-base lg:text-4xl">
            TODOS UNIDOS CONTRA LA ANEMIA Y DESNUTRICIÓN CRÓNICA INFANTIL
          </h1>
          <h2 class="font-semibold text-base" :class="isDark ? 'text-gray-800' : 'text-gray-600'">
            Sistema de Información Integrado de Vigilancia Nutricional Regional
          </h2>
        </div>
      </main>
    </div>

    <!-- desktop -->
    <div class="hidden md:block">
      <div class="w-full flex items-center justify-between">
        <router-link to="/" class="hidden md:block">
          <img
            :src="require(isDark ? '@/assets/region-tacna-black.png' : '@/assets/region-tacna.png')"
            class="w-32 md:w-40 lg:w-64"
            alt="Logo del Gobierno Regional de Tacna"
          />
        </router-link>

        <main class="text-center">
          <div class="text-center">
            <h1 class="text-blue-500 font-semibold text-base lg:text-lg font-sans">
              TODOS UNIDOS CONTRA LA ANEMIA Y DESNUTRICIÓN CRÓNICA INFANTIL
            </h1>
            <h2 class="font-semibold text-base" :class="isDark ? 'text-gray-800' : 'text-gray-600'">
              Sistema de Información Integrado de Vigilancia Nutricional Regional
            </h2>
          </div>
        </main>

        <div class="md:flex gap-3 items-center">
          <img
            :src="
              require(isDark
                ? '@/assets/logo-contra-anemia-black.png'
                : '@/assets/logo-contra-anemia.png')
            "
            class="w-16 md:w-14 lg:w-20"
            alt="Logo juntos contra la anemia"
          />
          <img
            :src="
              require(isDark ? '@/assets/logo-red-salud-black.png' : '@/assets/logo-red-salud.png')
            "
            class="w-16 lg:w-20 hidden md:block"
            alt="Logo de la red de salud Tacna"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isDark: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
